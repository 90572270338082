<template>
  <div class="login-page">
    <UiPreloader />
  </div>
</template>

<script setup lang="ts">
  import UiPreloader from '@academica-box/components/Loaders/UiPreloader/UiPreloader.vue'
  import { useUserStore } from '@academica-box/composables/store/user'

  const userStore = useUserStore()

  onMounted(() => {
    userStore.login()
  })

  useHead({
    title: 'Авторизация | Академика',
  })
</script>

<style lang="scss">
  .login-page {
    height: 100vh;
  }
</style>
